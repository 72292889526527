import React, { useEffect, useState, useRef } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import Header from "./Header";
import axiosInstance from "../axiosInstance";
import useDebounce from "./UseDebounce";
import LoaderIcon from "./LoaderIcon";
import { formatDate, timeAgo, chatContentWithLinks } from "../helpers/helpers";
import { FaFlag } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import ToggleButton from "./ToggleButton";

const Chat = () => {
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [selectConversation, setSelectConversation] = useState();
  const [isLoading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [chatBoatName, setChatBoatName] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [flaggedMessages, setFlaggedMessages] = useState([]);
  const [selectedOption, setSelectedOption] = useState("outstanding");
  const chatContainerRef = useRef(null);
  const location = useLocation();
  const { pathname } = location;
  const [isAdminPage, setIsAdminPage] = useState(pathname === "/admin")


  const [value, setValue] = useState({
    startDate: isAdminPage ? new Date(new Date().setDate(new Date().getDate() - 30)) : new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  });

  useEffect(() => {
    if(pathname === "/admin") {
      setIsAdminPage(true)
      setValue({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
      })
    } else {
      setIsAdminPage(false)
      setValue({
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(),
      })
    }
  }, [pathname])

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const fetchData = async (loadMore) => {
    if (loadMore) {
      setIsLoadingMore(true);
    } else {
      setLoading(true);
      setConversations([]);
      setFilteredConversations([]);
      setSelectConversation();
    }
    try {
      const response = await axiosInstance.get(
        `/chatbase/get-conversations?startDate=${formatDate(
          value.startDate
        )}&endDate=${formatDate(value.endDate)}&page=${loadMore ? pageNo + 1 : 1
        }&limit=${isAdminPage ? 100 : 10}&isAdmin=${isAdminPage}`
      );
      if (response.data && response.data.data) {
        const responseData = response.data.data;
        const toggleSelectedConversations =
          selectedOption === "outstanding"
            ? responseData
            : response.data.solvedConversations;
        if (loadMore) {
          if (pathname === "/admin") {
            setConversations([
              ...conversations,
              ...toggleSelectedConversations,
            ]);
          } else {
            setConversations([...conversations, ...responseData]);
          }
          setPageNo(pageNo + 1);
        } else {
          setChatBoatName(response?.data?.client?.name);
          setPageNo(1);
          if (pathname === "/admin") {
            setConversations([...toggleSelectedConversations]);
          } else {
            setConversations([...responseData]);
          }
        }
        setShowLoadMore(true);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [value, pathname, selectedOption]);

  useDebounce(
    () => {
      let tmpData = JSON.parse(JSON.stringify(conversations));
      tmpData = tmpData.filter((d) => {
        return d.messages.some((msg) =>
          msg.content.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredConversations(tmpData);
      if (tmpData && tmpData.length) {
        if (tmpData.length <= 10) {
          setSelectConversation(tmpData[0]);
        } else if (pathname === "/admin") {
          setSelectConversation(tmpData[0]);
        }
      }
    },
    [conversations, search],
    800
  );

  const getClientMessage = (messages) => {
    const clientMessages = messages.filter(
      (message) => message.role === "user"
    );
    return clientMessages.length
      ? clientMessages[0].content
      : messages[1].content;
  };

  const handleSearch = (e) => setSearch(e.target.value);

  const getFlaggedMessages = async () => {
    if (selectConversation) {
      try {
        const response = await axiosInstance.get(
          `messages/conversations-flagged-messages?conversationsId=${selectConversation.id}`
        );
        if (response.data.data && response.data.data.length) {
          const messageIds = response.data.data.map((data) => data.messageId);
          setFlaggedMessages(messageIds);
        } else {
          setFlaggedMessages([]);
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  useEffect(() => {
    getFlaggedMessages();
  }, [selectConversation]);

  const handleFlag = async (messageIndex) => {
    let messageId = `${selectConversation.id}Index${messageIndex}`;
    const payload = {
      messageId: messageId,
      conversationsId: selectConversation.id,
      flag: flaggedMessages.includes(messageId) ? false : true,
    };
    try {
      const response = await axiosInstance.post(
        "/messages/flag-unflag",
        payload
      );
      if (response.data) {
        const index = flaggedMessages.findIndex((id) => id === messageId);
        let messages = flaggedMessages;
        if (index < 0) {
          messages.push(messageId);
        } else {
          messages.splice(index, 1);
        }
        setFlaggedMessages([...messages]);
      }
    } catch (error) {
      console.error("There was a problem with the axios request:", error);
    }
  };

  const handleSolved = async () => {
    try {
      setLoading(true);
      setConversations([]);
      setFilteredConversations([]);
      setSelectConversation();
      await axiosInstance.post("/messages/resolve-flagged-messages", null, {
        params: {
          conversationsId: selectConversation.id,
        },
      });
      const updatedConversations = conversations.filter(
        (conversation) => conversation.id !== selectConversation.id
      );
      setConversations(updatedConversations);
      setLoading(false);
    } catch (err) {
      console.log("Error in handleSolved fun", err);
    }
  };

  useEffect(() => {
    if (pathname === "/admin") {
      let sortedFlaggedMessages = [...flaggedMessages];
      sortedFlaggedMessages.sort((a, b) => {
        const indexA = parseInt(a.split("Index")[1]);
        const indexB = parseInt(b.split("Index")[1]);
        return indexA - indexB;
      });
      if (sortedFlaggedMessages.length > 0 && chatContainerRef.current) {
        const flaggedMessageId = sortedFlaggedMessages[0];
        const index = parseInt(flaggedMessageId.split("Index")[1]);
        const messageElement = chatContainerRef.current.children[index];
        if (messageElement) {
          messageElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } else {
        chatContainerRef.current.scrollTop = 0;
      }
    }
  }, [flaggedMessages]);

  const toggleOption = (option) => {
    if (selectedOption !== option) {
      setSelectedOption(option);
    }
  };

  return (
    <>
      <Header chatboatName={chatBoatName} />
      <div className="container mx-auto shadow-lg rounded-lg">
        {/* <!-- headaer --> */}
        <div className="px-2 py-5 flex gap-8 justify-between items-center bg-white border-b-2">
          <div className="font-semibold text-2xl">
            {pathname === "/admin" ? (
              <ToggleButton
                toggleOption={toggleOption}
                selectedOption={selectedOption}
                setSelectedOption={toggleOption}
                leftText="Outstanding"
                rightText="Solved"
              />
            ) : (
              "Chat Logs"
            )}
          </div>
          <div className="flex justify-between items-center w-4/5">
            <div className="w-full mr-1">
              <Datepicker
                value={value}
                onChange={handleValueChange}
                showShortcuts={true}
              />
            </div>
          </div>
          {pathname === "/admin" &&
            !isLoading &&
            conversations.length !== 0 &&
            selectedOption !== "solved" && (
              <button
                onClick={handleSolved}
                className="py-2 px-4 rounded-[1rem] hover:bg-red-600 bg-red-500 text-white font-bold font-serif"
              >
                Solved
              </button>
            )}
        </div>

        {isLoading && (
          <div className="flex items-center justify-center min-h-96 p-5 min-w-screen">
            <LoaderIcon />
          </div>
        )}
        {!isLoading && !conversations.length && (
          <div className="text-center pt-3">
            No record found!! Please check filters....
          </div>
        )}
        <div
          className="flex flex-row justify-between bg-white"
          style={{ maxHeight: "calc(100vh - 160px)" }}
        >
          {/* <!-- chat list --> */}
          <div className="flex flex-col w-2/5 border-r-2 overflow-y-auto">
            {!isLoading && (
              <div className="border-b-2 py-4 px-2">
                <input
                  type="text"
                  placeholder="search..."
                  className="py-2 px-2 border-2 border-gray-200 rounded-2xl w-full"
                  onChange={handleSearch}
                />
              </div>
            )}
            {!isLoading &&
              filteredConversations.map((conversation) => (
                <div
                  key={conversation.id}
                  className={`border-b-2 flex flex-row py-4 px-2 items-center cursor-pointer 
                                ${selectConversation &&
                      selectConversation.id === conversation.id
                      ? "bg-slate-100"
                      : ""
                    }`}
                  onClick={() => setSelectConversation(conversation)}
                >
                  <div className="w-full">
                    <div className="text-mg font-normal text-right">
                      {timeAgo(conversation.created_at)}
                    </div>
                    <span className="text-gray-500 text-sm">
                      {conversation.messages && conversation.messages.length > 1
                        ? getClientMessage(conversation.messages)
                        : ""}
                    </span>
                  </div>
                </div>
              ))}
            {!isLoading && showLoadMore && (
              <div className="flex justify-center py-2">
                {isLoadingMore ? (
                  <button className="bg-gray-300 text-white rounded-full px-6 cursor-pointer py-4">
                    <LoaderIcon size={2} />
                  </button>
                ) : (
                  <button
                    className="bg-blue-400 text-white rounded-full px-6 cursor-pointer py-2 text-xl"
                    onClick={() => fetchData(true)}
                  >
                    Charger plus
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="w-full px-5 flex flex-col justify-between overflow-y-auto">
            <div className="flex flex-col mt-5" ref={chatContainerRef}>
              {selectConversation &&
                selectConversation.messages &&
                selectConversation.messages.length &&
                selectConversation.messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex mb-4 ${message.role === "assistant"
                      ? "justify-start"
                      : "justify-end"
                      }`}
                  >
                    <div
                      className={`flex items-center gap-1 whitespace-pre-wrap mr-2 py-3 px-4 ${message.role !== "assistant"
                        ? "text-white bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl"
                        : "bg-gray-200 rounded-br-3xl rounded-tr-3xl rounded-tl-xl"
                        }`}
                    >
                      {chatContentWithLinks(message.content)}
                      {message.role === "user" && (
                        <FaFlag
                          className="ml-1"
                          style={{
                            color:
                              flaggedMessages.length &&
                                flaggedMessages.includes(
                                  `${selectConversation.id}Index${index}`
                                )
                                ? "red"
                                : "grey",
                            cursor: "pointer",
                          }}
                          onClick={() => handleFlag(index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
