import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PiFlagPennantFill } from "react-icons/pi";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <header>
      <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 border-b-2">
        <div className="flex flex-wrap items-center justify-between mx-auto max-w-screen-xl">
          {/* Logo Section */}
          <div className="flex items-center">
            <img
              src="./OmnidexAI_logo.png"
              className="h-6 sm:h-9 cursor-pointer"
              alt="Omnidex-AI Logo"
              onClick={() => navigate("/chat")}
            />
          </div>

          {/* Navigation Items */}
          <div className="flex items-center gap-2 sm:gap-6 flex-nowrap">
            {/* <div
              className="cursor-pointer text-sm sm:text-base"
              onClick={() => navigate("/chat-playground")}
            >
              Playground
            </div> */}
            <div className="flex items-center cursor-pointer text-sm sm:text-base">
              <p className="flex items-center">
                {pathname === "/admin" ? (
                  <>
                    <PiFlagPennantFill
                      className="inline mr-1"
                      size={18}
                      style={{ color: "red" }}
                    />
                    <span className="font-bold">Flagged</span>
                  </>
                ) : (
                  // <span></span>
                  <span onClick={() => navigate("/analytics")}>Statistiques</span>
                )}
              </p>
            </div>
            <button
              className="select-none rounded-lg bg-white border-2 border-black py-1 px-3 text-xs sm:text-sm font-bold text-black shadow-md transition-all hover:shadow-lg whitespace-nowrap"
              type="button"
              onClick={() => logout()}
            >
              log out
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;